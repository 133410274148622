import {
  Component,
  Input,
  ElementRef,
  ViewChild,
  Output,
  EventEmitter,
  SimpleChanges,
  ChangeDetectionStrategy,
  inject,
  ChangeDetectorRef,
  Self,
  Optional,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NgControl,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'da-input',
  changeDetection: ChangeDetectionStrategy.OnPush,

  template: `
    <div
      class="tw-reset tw-bottom-1 tw-col-span-full tw-py-4 sm:tw-col-span-7 md:tw-col-span-5 md:tw-col-start-2 lg:tw-col-span-8 lg:tw-col-start-2 xl:tw-col-span-6 xl:tw-col-start-3"
    >
      <div
        class="tw-group tw-reset tw-relative tw-z-0 tw-w-full"
        [ngClass]="{ 'has-error': !!errorText }"
      >
        <textarea
          #textInput
          *ngIf="useTextArea"
          [id]="id"
          (input)="onInputChange($event)"
          (focus)="onFocus($event)"
          (blur)="onBlur($event)"
          (change)="handleEvent('change', $event)"
          [attr.rows]="calculateNumLines().numRows"
          [required]="required"
          [attr.aria-invalid]="!!errorText"
          [attr.aria-describedby]="
            errorText?.length ? errorText.join(' ') : null
          "
          class="tw-focus:tw-outline-none tw-focus:tw-ring-0 tw-group-[.has-error]:tw-border-red-200 tw-group-[.has-error]:tw-text-red-800 tw-group-[.has-error]:tw-placeholder-red-600 tw-focus:tw-group-[.has-error]:tw-border-red-800 tw-dark:tw-border-neutral-700 tw-dark:tw-text-neutral-100 tw-dark:tw-placeholder-neutral-400 tw-dark:tw-group-[.has-error]:tw-border-red-700 tw-dark:tw-group-[.has-error]:tw-text-red-100 tw-dark:tw-group-[.has-error]:tw-placeholder-red-400 tw-dark:tw-focus:tw-group-[.has-error]:tw-border-red-700 tw-peer tw-reset tw-absolute tw-mt-4 tw-block tw-w-full tw-appearance-none tw-border-0 tw-border-b-2 tw-border-neutral-200 tw-bg-transparent tw-px-0 tw-py-1 tw-text-neutral-800 tw-placeholder-neutral-600 placeholder:!tw-font-bold placeholder:!tw-text-neutral-600 read-only:tw-cursor-not-allowed read-only:tw-border-dashed read-only:tw-bg-neutral-100 dark:read-only:tw-bg-neutral-800"
          [attr.placeholder]="labelText"
          [attr.maxLength]="maxLength"
          [class.tw-text-base]="size === 'normal'"
          [class.tw-text-3xl]="size === 'large'"
          [attr.readonly]="readonly ? '' : null"
          [value]="value"
        ></textarea>
        <input
          #textInput
          *ngIf="!useTextArea"
          [type]="inputType"
          [id]="id"
          (input)="onInputChange($event)"
          (focus)="onFocus($event)"
          (blur)="onBlur($event)"
          (change)="handleEvent('change', $event)"
          [required]="required"
          [attr.aria-invalid]="!!errorText"
          [attr.aria-describedby]="
            errorText?.length ? errorText.join(' ') : null
          "
          class="tw-peer tw-block tw-w-full tw-appearance-none tw-border-0 tw-border-b-2 tw-border-neutral-200 tw-bg-transparent tw-px-0 tw-py-1 tw-text-3xl tw-font-bold tw-placeholder-neutral-600 placeholder:!tw-font-bold placeholder:!tw-text-neutral-600 placeholder:tw-opacity-0 read-only:tw-cursor-not-allowed read-only:tw-border-dashed read-only:tw-bg-neutral-100 focus:tw-border-blue-700 focus:tw-outline-none focus:tw-ring-0 focus:placeholder:tw-opacity-100 group-[.has-error]:tw-border-red-200 group-[.has-error]:tw-text-red-800 group-[.has-error]:tw-placeholder-red-600 focus:group-[.has-error]:tw-border-red-800 dark:tw-border-neutral-700 dark:tw-text-neutral-100 dark:tw-placeholder-neutral-400 dark:read-only:tw-bg-neutral-800 dark:focus:tw-border-blue-600 dark:group-[.has-error]:tw-border-red-700 dark:group-[.has-error]:tw-text-red-100 dark:group-[.has-error]:tw-placeholder-red-400 dark:focus:group-[.has-error]:tw-border-red-700 group-[.has-error]:[&:not(:focus)]:tw-pr-6 focus:[&:not(:placeholder-shown)]:tw-pr-6 group-hover:[&:not(:placeholder-shown)]:tw-pr-6"
          [placeholder]="labelText"
          [maxLength]="maxLength"
          [readonly]="readonly"
          [class.tw-text-base]="size === 'normal'"
          [class.tw-text-3xl]="size === 'large'"
          [attr.readonly]="readonly ? '' : null"
          [value]="value"
        />
        <label
          [for]="id"
          [class.tw-text-base]="size === 'normal'"
          [class.tw-text-3xl]="size === 'large'"
          [ngClass]="{
            'tw-text-base peer-placeholder-shown:tw-text-base':
              size === 'normal',
            'tw-text-3xl peer-placeholder-shown:tw-text-3xl': size === 'large',
          }"
          class="tw-absolute tw-top-1 tw-origin-[0] -tw-translate-y-5 tw-transform tw-cursor-text tw-text-xs tw-font-semibold tw-text-neutral-600 tw-duration-300 group-hover:tw-text-neutral-800 group-[.has-error]:tw-text-red-800 group-hover:group-[.has-error]:tw-text-red-800 peer-placeholder-shown:tw-translate-y-0 peer-placeholder-shown:tw-font-bold peer-read-only:tw-cursor-not-allowed peer-focus:tw-left-0 peer-focus:-tw-translate-y-5 peer-focus:tw-text-xs peer-focus:tw-font-semibold peer-focus:tw-text-blue-800 group-[.has-error]:peer-focus:tw-text-red-800 dark:tw-text-neutral-300 dark:group-hover:tw-text-neutral-200 dark:group-[.has-error]:tw-text-red-400 dark:group-hover:group-[.has-error]:tw-text-red-300 dark:peer-focus:tw-text-blue-600 dark:group-[.has-error]:peer-focus:tw-text-red-400"
        >
          {{ labelText }}
          <span *ngIf="required" aria-hidden="true" class="tw-text-red-800">
            *
          </span>
        </label>
        <button
          type="button"
          *ngIf="!readonly"
          (click)="resetField($event)"
          [class.tw-hidden]="!showResetButton"
          class="tw-dark:text-neutral-300 tw-absolute tw-bottom-0 tw-right-0 tw-top-0 tw-z-10 tw-hidden tw-items-center focus:tw-flex peer-focus:peer-[&:not(:placeholder-shown)]:peer-[&:not(:read-only)]:tw-flex group-hover:peer-enabled:peer-[&:not(:placeholder-shown)]:peer-[&:not(:read-only)]:tw-flex"
          [attr.aria-label]="'Core_Clear'"
        >
          <da-icon
            name="close"
            type="outline"
            icon="x-mark"
            [solidSize]="16"
            [className]="'tw-size-4 tw-text-neutral-600'"
          />
        </button>
        <button
          type="button"
          *ngIf="readonly"
          class="tw-dark:text-neutral-300 tw-absolute tw-bottom-6 tw-right-0 tw-top-0 tw-z-10 tw-flex tw-items-center"
          [attr.aria-label]="'Read Only'"
        >
          <da-icon
            name="lock"
            type="outline"
            icon="lock-closed"
            [solidSize]="16"
            [className]="'tw-size-4 tw-text-neutral-600'"
          />
        </button>
        <div
          *ngIf="helperText"
          [id]="id + '-helper'"
          class="tw-mt-1 tw-text-sm tw-text-neutral-600"
        >
          {{ helperText }}
        </div>
        <div
          *ngIf="isInvalid"
          [id]="id + '-error'"
          class="tw-mt-1 tw-text-sm tw-text-red-600"
          role="alert"
        >
          <div *ngFor="let error of errorText">
            {{ error }}
          </div>
        </div>
      </div>
    </div>
  `,
})
/**
 * A flexible input/textarea component with various customization options.
 *
 * @input labelText - Label for the input (required)
 * @input helperText - Additional guidance text
 * @input errorText - Error message to display
 * @input color - Color scheme ('blue' or 'purple', default: 'blue') - TBD
 * @input size - Size mode ('normal' or 'large', default: 'normal') - TBD
 * @input rows - Number of rows for textarea (default: 1)
 * @input required - Whether the field is required (default: false)
 * @input value - Current value of the input (default: '')
 * @input placeholder - Placeholder text (default: ' ')
 * @input maxLength - Maximum character length (default: 200)
 * @input id - Unique identifier for the input (required)
 *
 * @output inputEvent - Emits { eventType: string, event: Event } on input changes
 *
 */
export class InputComponent implements ControlValueAccessor, Validator {
  @Input() labelText!: string;
  @Input() helperText?: string;
  @Input() errorText?: string[];
  @Input() size: 'normal' | 'large' = 'normal';
  @Input() rows?: number = 1;
  @Input() required: boolean = false;
  public value: string = '';
  @Input() maxLength?: number = 200;
  @Input() id!: string;
  @Input() inputType: 'text' | 'password' | 'email' | 'url' | 'tel' | 'number' =
    'text';
  @Input() readonly: boolean = false;
  @Input() pattern?: string | RegExp = '';

  isInvalid: boolean = false;
  numRows: number;
  useTextArea: boolean;
  showResetButton: boolean = false;
  @ViewChild('textInput') textInput!: ElementRef<
    HTMLInputElement | HTMLTextAreaElement
  >;
  private cdr: ChangeDetectorRef = inject(ChangeDetectorRef);

  @Output() inputEvent: EventEmitter<{ eventType: string; event: Event }> =
    new EventEmitter();
  @Output() errorStateChange = new EventEmitter<boolean>();

  constructor(@Optional() @Self() public ngControl: NgControl) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  /**
   * Calculates the number of rows needed and determines if textarea should be used.
   * Blatantly copied from the react version.
   */
  calculateNumLines(): { numRows: number; useTextArea: boolean } {
    const numCharsPerLine = 100;

    const countLines = (text: string | undefined) => {
      return (text || '').split(/\r\n|\r|\n/g).reduce((acc, line) => {
        return acc + Math.ceil(line.length / numCharsPerLine);
      }, 0);
    };

    const textLength = this.value.length;
    const numRows =
      this.rows && this.rows > 1
        ? this.rows
        : Math.max(
            countLines(this.value),
            Math.ceil(textLength / numCharsPerLine)
          ) || 1;

    const useTextArea = numRows > 1;

    return { numRows, useTextArea };
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateNumLines();
    this.cdr.detectChanges();
  }

  /**
   * Resets the input field, clearing its value and resetting related properties.
   */
  resetField(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.value = '';
    this.showResetButton = false;
    const el = this.textInput.nativeElement;
    if (el instanceof HTMLTextAreaElement) {
      el.rows = 1;
    }
    el.focus();
  }

  updateNumLines(): void {
    const { numRows, useTextArea } = this.calculateNumLines();
    this.numRows = numRows;
    this.useTextArea = useTextArea;
  }

  onInputChange(event: Event): void {
    const inputElement = event.target as HTMLInputElement | HTMLTextAreaElement;
    this.value = inputElement.value;
    this.showResetButton = this.value.length > 0;
    this.onChange(this.value);
    this.onValidatorChange();
    this.updateNumLines();
  }

  onFocus(event: Event): void {
    this.showResetButton = this.value.length > 0;
    this.inputEvent.emit({ eventType: 'focus', event });
  }
  /**
   * Hide the clear/reset button.
   */
  onBlur(event: Event): void {
    // Delay hiding the button to allow for click events
    // When relying on the tailwind peer selector the click event does not fire as it hides the button.
    this.inputEvent.emit({ eventType: 'focus', event });
    setTimeout(() => {
      this.showResetButton = false;
    }, 200);
  }
  /**
   * Handle all the events.
   * @param eventType click, focus blur etc.
   * @param event the actual event
   */
  handleEvent(eventType: string, event: Event) {
    this.inputEvent.emit({ eventType, event });
  }

  setError(errorMessages: string[] | null): void {
    this.errorText = errorMessages;
  }
  writeValue(value: string): void {
    this.value = value;
    this.updateNumLines();
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (this.pattern) {
      const patternValidator = Validators.pattern(this.pattern);
      const result = patternValidator(control);
      this.isInvalid = !!result;
      return result;
    }
    this.updateErrorState(false);
    return null;
  }

  updateErrorState(isInvalid: boolean): void {
    if (this.isInvalid !== isInvalid) {
      this.isInvalid = isInvalid;
      this.errorStateChange.emit(this.isInvalid);
    }
  }

  registerOnValidatorChange(fn: () => void): void {
    this.onValidatorChange = fn;
  }

  private onChange: (value: string) => void = () => {};
  private onTouched: () => void = () => {};
  private onValidatorChange: () => void = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.readonly = isDisabled;
  }
}
